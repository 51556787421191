import React, { useState } from "react"
import styled from "styled-components"

import Book from "./components/Book"

const Container = styled.div`
  width: 100%;
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const InputField = styled.input`
  width: ${(props) => (props.type == "text" ? "200px" : "100px")};
  height: 30px;
  padding: 0px 5px;
  border: 1px solid black;
  margin: 0 10px;
  box-sizing: content-box;
  @media (max-width: 768px) {
    margin: 10px 0;
  }
`

const InputContainer = styled.form`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const Books = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`

const App = () => {
  const [search, setSearch] = useState("")
  const [price, setPrice] = useState(0)
  const [books, setBooks] = useState([])

  const searchBooks = () => {
    if (search === "") return
    fetch(
      `https://www.googleapis.com/books/v1/volumes?q=${search}&&country=BR&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
    )
      .then((res) => res.json())
      .then((result) => {
        let items = result.items
        items = items.filter((item) => {
          if (price > 0) {
            return (
              item.saleInfo.saleability == "FOR_SALE" &&
              item.saleInfo.retailPrice.amount < price
            )
          } else {
            return item.saleInfo.saleability == "FOR_SALE"
          }
        })
        setBooks(items)
      })
      .catch((err) => console.log(err))
  }

  return (
    <Container>
      <h3>Busque livros no Google Books</h3>

      <InputContainer
        onSubmit={(e) => {
          e.preventDefault()
          searchBooks()
        }}
      >
        <InputField
          type="text"
          placeholder="Titulo, autor, ISBN..."
          onChange={(e) => setSearch(e.target.value)}
        />
        <InputField
          placeholder="Preço Máximo"
          type="number"
          onChange={(e) => setPrice(e.target.value)}
        />
        <InputField
          type="submit"
          value="Pesquisar"
          onClick={() => searchBooks()}
        />
      </InputContainer>
      <p>Total de resultados: {books.length}</p>
      <Books>
        {books.map((book, index) => {
          return <Book key={index} book={book} />
        })}
      </Books>
    </Container>
  )
}

export default App
