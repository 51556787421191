import React from "react"
import styled from "styled-components"

const BookContainer = styled.div`
  display: flex;
  margin: 20px 0;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const BookImage = styled.img`
  margin-right: 20px;
  align-self: center;
`

const Title = styled.h2`
  margin: 0;
  color: #000;
  text-decoration: none;
  @media (max-width: 768px) {
    text-align: center;
  }
`
const Author = styled.h4`
  margin: 0;
  @media (max-width: 768px) {
    text-align: center;
  }
`
const BookInfo = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  @media (max-width: 768px) {
    max-width: fit-content;
    padding: 20px;
  }
`

const AttrTitle = styled.span`
  font-weight: bold;
`
const AttrName = styled.span`
  font-weight: normal;
`

const Attrs = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const Attr = styled.div`
  display: flex;
  flex-direction: column;
`

const Description = styled.p``
const Book = ({ book }) => {
  const identifiers =
    book.volumeInfo?.industryIdentifiers?.filter(
      (ii) => ii.type == "ISBN_13"
    ) || []
  let isbn_13
  if (identifiers.length > 0) {
    isbn_13 = identifiers[0].identifier
  } else {
    isbn_13 = ""
  }
  return (
    <BookContainer>
      <a href={book.saleInfo.buyLink} target="_blank">
        <BookImage src={book.volumeInfo?.imageLinks?.thumbnail} />
      </a>
      <BookInfo>
        <a href={book.saleInfo.buyLink} target="_blank">
          <Title>{book.volumeInfo?.title}</Title>
        </a>
        <Author>{book.volumeInfo.authors.join(", ")}</Author>
        <Description>{book.volumeInfo?.description}</Description>
        <Attrs>
          <Attr>
            <AttrTitle>ISBN</AttrTitle>
            <AttrName>{isbn_13}</AttrName>
          </Attr>
          <Attr>
            <AttrTitle>Preço</AttrTitle>
            <AttrName>
              R$
              {book.saleInfo?.retailPrice?.amount
                ?.toFixed(2)
                ?.replace(".", ",")}
            </AttrName>
          </Attr>
          <Attr>
            <AttrTitle>Categorias</AttrTitle>
            <AttrName>{book.volumeInfo?.categories?.join(", ")}</AttrName>
          </Attr>
        </Attrs>
      </BookInfo>
    </BookContainer>
  )
}

export default Book
